import React from "react"

import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { ButtonExternal } from "../components/Button/Button"
import SEO from "../components/SeoComponent"
import { useTranslation } from "react-i18next"

export default function Links() {
  if (typeof window !== "undefined") {
    document.body.style.overflow = "visible"
  }
  const { t } = useTranslation()

  return (
    <Layout path={"links"}>
      <SEO title={"Nearby restaurants"} />
      <section className="links">
        <div className="background-box"></div>
        <h1 className="links-title h2">{t("links.1")}</h1>
        <article className="links-item links-top3 links-one">
          <StaticImage
            src="../images/gasthof.png"
            width={554}
            height={320}
            quality={95}
            placeholder="blurred"
            imgStyle={{ objectFit: "cover" }}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Gasthof-Heidelberg"
          />
          <div className="links-container">
            <h2 className="links-item-title">Gasthof-Heidelberg</h2>
            <p className="links-item-text">{t("links.2")}</p>
            <ButtonExternal
              path={"http://www.gasthof-heidelberg.be/"}
              text={t("links.8")}
            />
          </div>
        </article>
        <article className="links-item links-top3 links-two">
          <div className="links-two-container">
            <StaticImage
              src="../images/jade.png"
              width={662}
              height={382}
              quality={95}
              placeholder="blurred"
              imgStyle={{ objectFit: "cover", position: "absolute", right: 0 }}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Restaurant De Jade"
            />
          </div>
          <div className="links-container">
            <h2 className="links-item-title">Restaurant De Jade</h2>
            <p className="links-item-text">{t("links.3")}</p>
            <ButtonExternal
              path={"https://www.jade-loppem.be/"}
              text={t("links.8")}
            />
          </div>
        </article>
        <article className="links-item links-top3 links-three">
          <StaticImage
            src="../images/herborist.png"
            width={696}
            height={382}
            placeholder="blurred"
            quality={95}
            imgStyle={{ objectFit: "cover" }}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Restaurant Herborist"
          />
          <div className="links-container">
            <h2 className="links-item-title">Restaurant De Herborist</h2>
            <p className="links-item-text">{t("links.4")}</p>
            <ButtonExternal
              path={"https://www.aubergedeherborist.be/"}
              text={t("links.8")}
            />
          </div>
        </article>
        <div className="links-others">
          <article className="links-item">
            <div style={{ display: "grid" }}>
              <StaticImage
                src="../images/sven.png"
                width={356}
                height={260}
                placeholder="blurred"
                quality={95}
                imgStyle={{ objectFit: "cover" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Bij Sven"
              />
            </div>
            <h2 className="links-item-title">Bij Sven</h2>
            <p className="links-item-text">{t("links.5")}</p>
            <ButtonExternal
              path={"https://www.bistrobysven.be/"}
              text={t("links.8")}
            />
          </article>
          <article className="links-item">
            <div style={{ display: "grid" }}>
              <StaticImage
                src="../images/boschvogel.png"
                width={356}
                height={260}
                placeholder="blurred"
                quality={95}
                imgStyle={{ objectFit: "cover" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Bistro Boschvogel"
              />
            </div>
            <h2 className="links-item-title">Bistro Boschvogel</h2>
            <p className="links-item-text">{t("links.6")}</p>
            <ButtonExternal
              path={"http://www.bistroboschvogel.be/"}
              text={t("links.8")}
            />
          </article>
          <article className="links-item">
            <div style={{ display: "grid" }}>
              <StaticImage
                src="../images/tenvoute.png"
                width={356}
                height={260}
                placeholder="blurred"
                quality={95}
                imgStyle={{ objectFit: "cover" }}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Ten Voute"
              />
            </div>
            <h2 className="links-item-title">Ten Voute</h2>
            <p className="links-item-text">{t("links.7")}</p>
            <ButtonExternal
              path={"https://www.tenvoute.be/"}
              text={t("links.8")}
            />
          </article>
        </div>
      </section>
    </Layout>
  )
}
